<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadPlantContracts(true)" />
                </div>
                <div class="col-auto">
                    <span style="line-height: 40px;">
                        {{plantContracts.tag}}
                    </span>
                </div>
            </div>
            <el-table :data="plantContracts.data" class="mt-2">
                <el-table-column prop="year" label="年份" sortable width="80" />
                <el-table-column label="名称/编号">
                    <template #default="scope">
                        <div>
                            <router-link :to="'/plant-contract/detail?id='+scope.row.id" target="_blank">
                                {{scope.row.name}}
                            </router-link>
                        </div>
                        <div class="text-info">{{scope.row.code}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="种植户/担保人" width="200">
                    <template #default="scope">
                        <div>
                            <router-link :to="'/registered-user/detail?id='+scope.row.farmerId" target="_blank">
                                种植户： {{scope.row.farmerName}}
                            </router-link>
                        </div>
                        <div class="text-info">担保人：{{scope.row.warrantorNames}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="totalArea" label="总面积（亩）" sortable width="160" />
                <el-table-column label="承包日期" width="140">
                    <template #default="scope">
                        <div>{{$moment.ld(scope.row.beginContractDate)}}</div>
                        <div>{{$moment.ld(scope.row.endContractDate)}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="250">
                    <template #default="scope">
                        <el-button type="text" @click="showToDosDialog(scope.row)">履约</el-button>
                        <el-button type="text" @click="showGrantPlantPointCardDialog(scope.row)">发放积分</el-button>
                        <el-button type="text" @click="showPlantInsurancesDialog(scope.row)">保险</el-button>
                        <el-button type="text" @click="showPlantSubsidiesDialog(scope.row)">补贴</el-button>
                        <el-button type="text" @click="showArchivePlantContractDialog(scope.row)">归档</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="plantContracts.totalRecords"
                :current-page="plantContractsPagination.currentPage" :page-size="plantContractsPagination.pageSize"
                class="mt-3" @current-change="plantContractsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="toDosDialogVisible" title="履约" :close-on-click-modal="false" width="960px">
            <to-dos v-if="toDosDialogVisible" :plant-contract-id="plantContract.id" />
        </el-dialog>

        <el-dialog :visible.sync="grantPlantPointCardDialogVisible" title="发放积分" :close-on-click-modal="false"
            width="800px">
            <div style="margin: 0 -16px;">
                <el-form>
                    <div class="form-group-title">履约</div>
                    <div class="form-group">
                        <el-table :data="toDos" border>
                            <el-table-column prop="title" label="履约事件" sortable />
                            <el-table-column prop="reminderTime" label="履约时间" sortable :formatter="$moment.sdtFormatter"
                                width="150" />
                            <el-table-column prop="isCompletedName" label="是否完成" width="80" />
                        </el-table>
                    </div>

                    <div class="form-group-title">发放</div>
                    <div class="form-group">
                        <div class="row no-gutters">
                            <div class="col">
                                <el-form-item label="总面积（亩）">
                                    <el-input v-model="plantContract.totalArea" readonly placeholder="总面积" />
                                </el-form-item>
                            </div>
                            <div class="col-auto px-2">
                                <div style="margin-top: 40px;">×</div>
                            </div>
                            <div class="col">
                                <el-form-item label="种植积分单价（元/亩）">
                                    <el-input v-model="plantContract.plantPointUnitPrice" readonly
                                        placeholder="种植积分单价" />
                                </el-form-item>
                            </div>
                            <div class="col-auto px-2">
                                <div style="margin-top: 40px;">=</div>
                            </div>
                            <div class="col">
                                <el-form-item label="总种植积分（元）">
                                    <el-input v-model="plantContract.totalPlantPoint" readonly placeholder="总种植积分" />
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                </el-form>
            </div>
            <template #footer>
                <span v-if="toDos.filter(p=>!p.isCompleted).length!=0" class="text-danger mr-3">履约未完成</span>
                <el-button :disabled="toDos.filter(p=>!p.isCompleted).length!=0" type="primary"
                    @click="grantPlantPointCard">
                    确定
                </el-button>
                <el-button @click="grantPlantPointCardDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <el-dialog :visible.sync="plantInsurancesDialogVisible" title="保险" :close-on-click-modal="false" width="960px">
            <plant-insurances v-if="plantInsurancesDialogVisible" :plant-contract-id="plantContract.id" />
        </el-dialog>

        <el-dialog :visible.sync="plantSubsidiesDialogVisible" title="补贴" :close-on-click-modal="false" width="960px">
            <plant-subsidies v-if="plantSubsidiesDialogVisible" :plant-contract-id="plantContract.id" />
        </el-dialog>

        <el-dialog :visible.sync="archivePlantContractDialogVisible" title="归档" :close-on-click-modal="false"
            width="480px">
            <el-form>
                <el-form-item label="状态" class="form-item-required">
                    <el-radio-group v-model="plantContract.status" class="stretch-radio-group">
                        <el-radio-button :label="98">完结归档</el-radio-button>
                        <el-radio-button :label="99">作废归档</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="plantContract.remark" type="textarea" :rows="5" placeholder="备注" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="archivePlantContract">确定</el-button>
                <el-button @click="archivePlantContractDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import ToDos from './components/ToDos.vue';
    import PlantInsurances from './components/PlantInsurances.vue';
    import PlantSubsidies from './components/PlantSubsidies.vue';

    export default {
        data() {
            return {
                key: null,
                plantContracts: {},
                plantContractsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                plantContract: {},
                toDos: [],
                toDosDialogVisible: false,
                grantPlantPointCardDialogVisible: false,
                plantInsurancesDialogVisible: false,
                plantSubsidiesDialogVisible: false,
                archivePlantContractDialogVisible: false,
            };
        },
        components: { ToDos, PlantInsurances, PlantSubsidies },
        methods: {
            async loadPlantContracts(resetPage = false) {
                if (resetPage) {
                    this.plantContractsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/PlantContract/GetPlantContracts', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: 5,
                        key: this.key,
                        pageIndex: this.plantContractsPagination.currentPage - 1,
                        pageSize: this.plantContractsPagination.pageSize,
                    }
                });
                this.plantContracts = response.data;
            },
            plantContractsPaginationCurrentChange(page) {
                this.plantContractsPagination.currentPage = page;
                this.loadPlantContracts();
            },
            showToDosDialog(plantContract) {
                this.plantContract = JSON.parse(JSON.stringify(plantContract));
                this.toDosDialogVisible = true;
            },
            async loadToDos() {
                this.toDos = [];
                let response = await this.$axios.get('/api/ToDo/GetEventToDos', { params: { eventId: this.plantContract.id } });
                this.toDos = response.data;
            },
            showGrantPlantPointCardDialog(plantContract) {
                this.plantContract = JSON.parse(JSON.stringify(plantContract));
                this.grantPlantPointCardDialogVisible = true;
                this.loadToDos();
            },
            async grantPlantPointCard() {
                if (!confirm('确定要发放吗？')) {
                    return;
                }

                await this.$axios.post('/api/PlantContract/GrantPlantPointCard', {
                    id: this.plantContract.id,
                });
                this.grantPlantPointCardDialogVisible = false;
                this.loadPlantContracts();
            },
            showPlantInsurancesDialog(plantContract) {
                this.plantContract = JSON.parse(JSON.stringify(plantContract));
                this.plantInsurancesDialogVisible = true;
            },
            showPlantSubsidiesDialog(plantContract) {
                this.plantContract = JSON.parse(JSON.stringify(plantContract));
                this.plantSubsidiesDialogVisible = true;
            },
            showArchivePlantContractDialog(plantContract) {
                this.plantContract = JSON.parse(JSON.stringify(plantContract));
                this.plantContract.status = 98;
                this.archivePlantContractDialogVisible = true;
            },
            async archivePlantContract() {
                if (!confirm('确定要归档吗？')) {
                    return;
                }

                await this.$axios.post('/api/PlantContract/ArchivePlantContract', {
                    id: this.plantContract.id,
                    status: this.plantContract.status,
                    remark: this.plantContract.remark,
                });
                this.archivePlantContractDialogVisible = false;
                this.loadPlantContracts();
            },
        },
        created() {
            this.loadPlantContracts();
        },
    };
</script>

<style>
    .form-group-title {
        background-color: #f2f6fc;
        padding: 1em 3em;
        border-left: 4px solid #409eff;
        font-weight: bold;
    }

    .form-group {
        padding: 1em 3em;
    }
</style>