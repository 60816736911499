<template>
    <div>
        <div class="row no-gutters">
            <div class="col">
                <el-button type="primary" @click="showPlantInsuranceDialog(null)">添加</el-button>
            </div>
        </div>
        <el-table :data="plantInsurances" class="mt-2">
            <el-table-column prop="insuranceCompany" label="保险公司" sortable />
            <el-table-column prop="insuranceCategory" label="险种" sortable />
            <el-table-column prop="validityBeginDate" label="有效起始日期" sortable :formatter="$moment.sdFormatter"
                width="150" />
            <el-table-column prop="validityEndDate" label="有效截止日期" sortable :formatter="$moment.sdFormatter"
                width="150" />
            <el-table-column prop="insuranceAmount" label="保费金额" sortable />
            <el-table-column label="操作" fixed="right" width="100">
                <template #default="scope">
                    <el-button type="text" @click="showPlantInsuranceDialog(scope.row)">修改</el-button>
                    <el-button type="text" class="text-danger" @click="deletePlantInsurance(scope.row.id)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :visible.sync="plantInsuranceDialogVisible" title="保险" :close-on-click-modal="false" append-to-body
            width="720px">
            <el-form>
                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="保险公司" class="form-item-required">
                            <el-input v-model="plantInsurance.insuranceCompany" placeholder="保险公司" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="险种" class="form-item-required">
                            <el-input v-model="plantInsurance.insuranceCategory" placeholder="险种" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="6">
                        <el-form-item label="有效起始日期" class="form-item-required">
                            <el-date-picker v-model="plantInsurance.validityBeginDate" placeholder="有效起始日期" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="有效截止日期" class="form-item-required">
                            <el-date-picker v-model="plantInsurance.validityEndDate" placeholder="有效截止日期" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="保费金额" class="form-item-required">
                            <el-input-number v-model="plantInsurance.insuranceAmount" controls-position="right" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="保险责任及内容">
                    <el-input v-model="plantInsurance.content" type="textarea" placeholder="保险责任及内容" />
                </el-form-item>

                <el-form-item label="备注">
                    <el-input v-model="plantInsurance.remark" type="textarea" placeholder="备注" />
                </el-form-item>

                <div class="mt-3">
                    <div>参保附件</div>
                    <div class="mt-2">
                        <div class="border rounded p-1">
                            <multi-file-uploader v-model="plantInsurance.attachments" accept=".pdf,.jpg,.jpeg,.png" />
                        </div>
                    </div>
                </div>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="savePlantInsurance">确定</el-button>
                <el-button @click="plantInsuranceDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            plantContractId: { type: String },
        },
        data() {
            return {
                plantInsurances: [],
                plantInsurance: {},
                plantInsuranceDialogVisible: false,
            };
        },
        methods: {
            async loadPlantInsurances() {
                let response = await this.$axios.get('/api/PlantInsurance/GetPlantInsurances', {
                    params: {
                        companyId: this.$store.state.companyId,
                        plantContractId: this.plantContractId,
                    }
                });
                this.plantInsurances = response.data.data;
            },
            showPlantInsuranceDialog(plantInsurance) {
                if (plantInsurance) {
                    this.plantInsurance = JSON.parse(JSON.stringify(plantInsurance));
                }
                else {
                    this.plantInsurance = { insuranceAmount: 0 };
                }
                this.plantInsuranceDialogVisible = true;
            },
            async savePlantInsurance() {
                if (this.plantInsurance.id == undefined) {
                    await this.$axios.post('/api/PlantInsurance/AddPlantInsurance', {
                        plantContractId: this.plantContractId,
                        insuranceCompany: this.plantInsurance.insuranceCompany,
                        insuranceCategory: this.plantInsurance.insuranceCategory,
                        validityBeginDate: this.$moment.sd(this.plantInsurance.validityBeginDate),
                        validityEndDate: this.$moment.sd(this.plantInsurance.validityEndDate),
                        insuranceAmount: this.plantInsurance.insuranceAmount,
                        content: this.plantInsurance.content,
                        remark: this.plantInsurance.remark,
                        attachments: this.plantInsurance.attachments,
                    });
                    this.plantInsuranceDialogVisible = false;
                    this.loadPlantInsurances();
                }
                else {
                    await this.$axios.post('/api/PlantInsurance/UpdatePlantInsurance', {
                        id: this.plantInsurance.id,
                        insuranceCompany: this.plantInsurance.insuranceCompany,
                        insuranceCategory: this.plantInsurance.insuranceCategory,
                        validityBeginDate: this.$moment.sd(this.plantInsurance.validityBeginDate),
                        validityEndDate: this.$moment.sd(this.plantInsurance.validityEndDate),
                        insuranceAmount: this.plantInsurance.insuranceAmount,
                        content: this.plantInsurance.content,
                        remark: this.plantInsurance.remark,
                        attachments: this.plantInsurance.attachments,
                    });
                    this.plantInsuranceDialogVisible = false;
                    this.loadPlantInsurances();
                }
            },
            async deletePlantInsurance(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/PlantInsurance/DeletePlantInsurance', { id: id });
                this.loadPlantInsurances();
            },
        },
        created() {
            this.loadPlantInsurances();
        },
    };
</script>