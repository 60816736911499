<template>
    <div>
        <div class="row no-gutters">
            <div class="col">
                <el-button type="primary" @click="showPlantSubsidyDialog(null)">添加</el-button>
            </div>
        </div>
        <el-table :data="plantSubsidies" class="mt-2">
            <el-table-column prop="subsidyProgram" label="补贴项目" sortable />
            <el-table-column prop="subsidyAmount" label="补贴金额" sortable />
            <el-table-column prop="subsidyMethod" label="补贴方式" sortable />
            <el-table-column prop="grantDate" label="发放日期" sortable :formatter="$moment.sdFormatter" width="150" />
            <el-table-column label="操作" fixed="right" width="140">
                <template #default="scope">
                    <el-button v-if="!scope.row.grantDate" type="text" @click="showGrantPlantSubsidyDialog(scope.row)">
                        发放</el-button>
                    <el-button v-if="!scope.row.grantDate" type="text" @click="showPlantSubsidyDialog(scope.row)">修改
                    </el-button>
                    <el-button v-if="!scope.row.grantDate" type="text" class="text-danger"
                        @click="deletePlantSubsidy(scope.row.id)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :visible.sync="plantSubsidyDialogVisible" title="补贴" :close-on-click-modal="false" append-to-body
            width="480px">
            <el-form>
                <el-form-item label="补贴项目" class="form-item-required">
                    <el-input v-model="plantSubsidy.subsidyProgram" placeholder="补贴项目" />
                </el-form-item>
                <el-form-item label="补贴金额" class="form-item-required">
                    <el-input-number v-model="plantSubsidy.subsidyAmount" controls-position="right" />
                </el-form-item>
                <el-form-item label="补贴方式" class="form-item-required">
                    <el-input v-model="plantSubsidy.subsidyMethod" placeholder="补贴方式" />
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="plantSubsidy.remark" type="textarea" placeholder="备注" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="savePlantSubsidy">确定</el-button>
                <el-button @click="plantSubsidyDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <el-dialog :visible.sync="grantPlantSubsidyDialogVisible" title="发放补贴" :close-on-click-modal="false"
            append-to-body width="400px">
            <el-form>
                <el-form-item label="发放日期" class="form-item-required">
                    <el-date-picker v-model="plantSubsidy.grantDate" placeholder="发放日期" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="grantPlantSubsidy">确定</el-button>
                <el-button @click="grantPlantSubsidyDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            plantContractId: { type: String },
        },
        data() {
            return {
                plantSubsidies: [],
                plantSubsidy: {},
                plantSubsidyDialogVisible: false,
                grantPlantSubsidyDialogVisible: false,
            };
        },
        methods: {
            async loadPlantSubsidies() {
                let response = await this.$axios.get('/api/PlantSubsidy/GetPlantSubsidies', {
                    params: {
                        companyId: this.$store.state.companyId,
                        plantContractId: this.plantContractId,
                    }
                });
                this.plantSubsidies = response.data.data;
            },
            showPlantSubsidyDialog(plantSubsidy) {
                if (plantSubsidy) {
                    this.plantSubsidy = JSON.parse(JSON.stringify(plantSubsidy));
                }
                else {
                    this.plantSubsidy = { subsidyAmount: 0 };
                }
                this.plantSubsidyDialogVisible = true;
            },
            async savePlantSubsidy() {
                if (this.plantSubsidy.id == undefined) {
                    await this.$axios.post('/api/PlantSubsidy/AddPlantSubsidy', {
                        plantContractId: this.plantContractId,
                        subsidyProgram: this.plantSubsidy.subsidyProgram,
                        subsidyAmount: this.plantSubsidy.subsidyAmount,
                        subsidyMethod: this.plantSubsidy.subsidyMethod,
                        remark: this.plantSubsidy.remark,
                    });
                    this.plantSubsidyDialogVisible = false;
                    this.loadPlantSubsidies();
                }
                else {
                    await this.$axios.post('/api/PlantSubsidy/UpdatePlantSubsidy', {
                        id: this.plantSubsidy.id,
                        subsidyProgram: this.plantSubsidy.subsidyProgram,
                        subsidyAmount: this.plantSubsidy.subsidyAmount,
                        subsidyMethod: this.plantSubsidy.subsidyMethod,
                        remark: this.plantSubsidy.remark,
                    });
                    this.plantSubsidyDialogVisible = false;
                    this.loadPlantSubsidies();
                }
            },
            showGrantPlantSubsidyDialog(plantSubsidy) {
                this.plantSubsidy = JSON.parse(JSON.stringify(plantSubsidy));
                this.grantPlantSubsidyDialogVisible = true;
            },
            async grantPlantSubsidy() {
                await this.$axios.post('/api/PlantSubsidy/GrantPlantSubsidy', {
                    id: this.plantSubsidy.id,
                    grantDate: this.$moment.sd(this.plantSubsidy.grantDate),
                });
                this.grantPlantSubsidyDialogVisible = false;
                this.loadPlantSubsidies();
            },
            async deletePlantSubsidy(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/PlantSubsidy/DeletePlantSubsidy', { id: id });
                this.loadPlantSubsidies();
            },
        },
        created() {
            this.loadPlantSubsidies();
        },
    };
</script>