<template>
    <div>
        <div class="row no-gutters">
            <div class="col">
                <el-button type="primary" @click="showToDoDialog(null)">添加</el-button>
            </div>
        </div>
        <el-table :data="toDos" class="mt-2">
            <el-table-column prop="title" label="履约事件" sortable />
            <el-table-column prop="reminderTime" label="履约时间" sortable :formatter="$moment.sdtFormatter" width="150" />
            <el-table-column prop="reminderUsers" label="提醒人员" width="100">
                <template #default="scope">
                    {{scope.row.reminderUsers.length}} 人
                </template>
            </el-table-column>
            <el-table-column prop="isCompletedName" label="是否完成" width="80" />
            <el-table-column label="操作" fixed="right" width="140">
                <template #default="scope">
                    <el-button v-if="!scope.row.isCompleted" type="text" @click="completeToDo(scope.row.id)">完成
                    </el-button>
                    <el-button v-if="!scope.row.isCompleted" type="text" @click="showToDoDialog(scope.row)">修改
                    </el-button>
                    <el-button v-if="!scope.row.isCompleted" type="text" class="text-danger"
                        @click="deleteToDo(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :visible.sync="toDoDialogVisible" title="履约" :close-on-click-modal="false" append-to-body
            width="720px">
            <el-form>
                <el-row :gutter="8">
                    <el-col :span="16">
                        <el-form-item label="履约事件" class="form-item-required">
                            <el-input v-model="toDo.title" placeholder="履约事件" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="履约时间" class="form-item-required">
                            <el-date-picker v-model="toDo.reminderTime" type="datetime" placeholder="履约时间" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div class="mt-2">
                <div>提醒人员</div>
                <div class="border rounded mt-2" style="padding: 2px;">
                    <el-tag v-for="reminderUser in toDo.reminderUsers" :key="reminderUser.id" type="info" closable
                        style="margin: 2px;" @close="removeReminderUser(reminderUser)">
                        {{reminderUser.name}}
                    </el-tag>
                    <el-button icon="el-icon-plus" size="small" style="margin: 2px;"
                        @click="selectRegisteredUserDialogVisible=true">
                        添加
                    </el-button>
                </div>
            </div>
            <template #footer>
                <el-button type="primary" @click="saveToDo">确定</el-button>
                <el-button @click="toDoDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <select-registered-user-dialog v-model="selectRegisteredUserDialogVisible" :is-enterprise="false"
            @select="selectRegisteredUser" />
    </div>
</template>

<script>
    export default {
        props: {
            plantContractId: { type: String },
        },
        data() {
            return {
                toDos: [],
                toDo: {},
                toDoDialogVisible: false,
                selectRegisteredUserDialogVisible: false,
            };
        },
        methods: {
            async loadToDos() {
                let response = await this.$axios.get('/api/ToDo/GetEventToDos', { params: { eventId: this.plantContractId } });
                this.toDos = response.data;
            },
            showToDoDialog(toDo) {
                if (toDo) {
                    this.toDo = JSON.parse(JSON.stringify(toDo));
                }
                else {
                    this.toDo = { reminderUsers: [] };
                }
                this.toDoDialogVisible = true;
            },
            selectRegisteredUser(user) {
                if (this.toDo.reminderUsers.filter(p => p.id == user.id).length != 0) {
                    return;
                }

                this.toDo.reminderUsers.push({ id: user.id, name: user.name, phone: user.phone });
            },
            removeReminderUser(reminderUser) {
                let index = this.toDo.reminderUsers.indexOf(reminderUser);
                this.toDo.reminderUsers.splice(index, 1);
            },
            async saveToDo() {
                if (this.toDo.id == undefined) {
                    await this.$axios.post('/api/ToDo/AddToDo', {
                        type: 1,
                        eventId: this.plantContractId,
                        title: this.toDo.title,
                        tags: '种植合同',
                        reminderTime: this.$moment.sdt(this.toDo.reminderTime),
                        reminderUsers: this.toDo.reminderUsers,
                    });
                    this.toDoDialogVisible = false;
                    this.loadToDos();
                }
                else {
                    await this.$axios.post('/api/ToDo/UpdateToDo', {
                        id: this.toDo.id,
                        title: this.toDo.title,
                        tags: '种植合同',
                        reminderTime: this.$moment.sdt(this.toDo.reminderTime),
                        reminderUsers: this.toDo.reminderUsers,
                    });
                    this.toDoDialogVisible = false;
                    this.loadToDos();
                }
            },
            async deleteToDo(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/ToDo/DeleteToDo', { id: id });
                this.loadToDos();
            },
            async completeToDo(id) {
                if (!confirm('确定要完成吗？')) {
                    return;
                }

                await this.$axios.post('/api/ToDo/CompleteToDo', { id: id });
                this.loadToDos();
            },
        },
        created() {
            this.loadToDos();
        },
    };
</script>